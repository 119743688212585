<template>
  <div style="padding: calc(10px + 1vw)">
    <div>
      <h2 style="text-align: center">{{ $t("reports_contracts.title") }}</h2>
    </div>
    <div>
      <v-row class="date-filter-container">
        <v-col cols="5" class="date-picker">
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                :label="$t('document.start_date')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="startDate" no-title @input="menu1 = false">
            </v-date-picker>
          </v-menu>
          <p @click="startDate = ''" class="pointer">
            <v-icon>mdi-close</v-icon>
          </p>
        </v-col>
        <v-col cols="5" class="date-picker">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                :label="$t('document.end_date')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="endDate" no-title @input="menu2 = false">
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-menu>
          <p @click="endDate = ''" class="pointer">
            <v-icon>mdi-close</v-icon>
          </p>
        </v-col>
        <v-col cols="2" class="d-flex justify-space-between">
          <v-btn depressed color="primary" @click="handleClick">
            {{ $t("search") }}
          </v-btn>
          <v-btn depressed color="primary px-2" @click="handleDownload">
            {{ $t("download") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="15"
      class="table-report"
      :footer-props="{
        itemsPerPageOptions: [20, 50, 100, -1],
        itemsPerPageAllText: $t('itemsPerPageAllText'),
        itemsPerPageText: $t('itemsPerPageText'),
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right',
      }"
      :server-items-length="server_items_length"
      @update:page="handleClick"
      @update:items-per-page="handleClick"
      :options.sync="pagination"
      :disable-pagination="true"
      disable-sort
    >
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      items: [],
      loading: false,
      endDate: "",
      startDate: "",
      menu1: false,
      menu2: false,
      server_items_length: 0,
      pagination: {
        page: 1,
        itemsPerPage: 20,
      },
    };
  },
  methods: {
    async getList() {
      this.loading = true;
      try {
        const res = await axios.get(
          `${this.$store.state.backend_url}api/report-contracts?document_date=${
            (this.startDate || this.endDate) &&
            `${this.startDate}|${this.endDate}`
          }&rows=${this.pagination.itemsPerPage}&page=${this.pagination.page}`
        );
        this.server_items_length = res.data.meta?.total || 0;
        this.items = res.data?.data || [];

        const beforePage =
          (this.pagination.page - 1) * this.pagination.itemsPerPage;
        this.items.forEach((item, index) => {
          item.no = beforePage + index + 1;
        });
        this.loading = false;
      } catch (error) {
        console.dir({ error });
      }
      this.loading = false;
    },
    getDoers(item, field) {
      return item.doers?.map((doer) => doer?.[field]).join(", ") || "";
    },
    handleClick() {
      this.getList();
    },
    handleDownload() {
      axios({
        url: `${this.$store.state.backend_url}api/report-contracts-download`,
        method: "GET",
        responseType: "blob",
        headers: { Accept: "application/vnd.ms-excel" },
       })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "Excel.xlsx");

          document.body.appendChild(fileLink);

          fileLink.click();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handlePagination(pagination) {
      console.log({ pagination });
    },
    getDate(data) {
      if (!data) {
        return "";
      }
      const date = new Date(data);
      const yyyy = date.getFullYear();
      const mm = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const dd = String(date.getDate()).padStart(2, "0");
      return `${yyyy}-${mm}-${dd}`;
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "№",
          value: "no",
          align: "center",
        },
        {
          text: this.$t("reports_contracts.company"),
          value: "company",
          align: "center",
        },
        {
          text: this.$t("reports_contracts.number"),
          value: "number",
          align: "center",
        },
        {
          text: this.$t("reports_contracts.contractor"),
          value: "contractor",
          align: "center",
        },
        {
          text: this.$t("reports_contracts.field-title"),
          value: "title",
          align: "center",
        },
        {
          text: this.$t("reports_contracts.date"),
          value: "date",
          align: "center",
        },
        {
          text: this.$t("reports_contracts.valyuta"),
          value: "currency",
          align: "center",
        },
        {
          text: this.$t("reports_contracts.valyuta_summ"),
          value: "currency_sum",
          align: "center",
        },
        {
          text: this.$t("reports_contracts.summa"),
          value: "sum",
          align: "center",
        },
        {
          text: this.$t("reports_contracts.category"),
          value: "category",
          align: "center",
        },
        {
          text: this.$t("reports_contracts.notes"),
          value: "notes",
          align: "center",
        },
      ];
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style>
.table-report header {
  color: red;
}
.table-report .button {
  /* width: 300px; */
  font-size: 12px;
  font-weight: bol;
  padding: 2px 5px;
  margin: 5px 10px;
  border-radius: 10px;
  border: 1px solid rgb(0, 0, 0);
  display: block;
  color: black;
  text-decoration: none;
  align-items: center;
  display: flex;
  justify-content: center;
}

.table-rep .v-data-table__wrapper table td {
  width: 18%;
}

.table-rep th {
  width: 18%;
}

.button .td-width {
  width: 20px;
}

.date-filter-container .date-picker {
  display: flex;
  align-items: center;
  gap: 5px;
}

.date-filter-container .pointer {
  cursor: pointer;
}

.date-filter-container .v-text-field__slot {
  padding: 4px !important;
}
</style>
